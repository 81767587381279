'use client';

import { useAtomValue } from 'jotai';
import { ReactElement } from 'react';
import styles from './GradientCircles.module.scss';
import { theme } from '../../store/theme';
import clientDeviceSize, { ScreenSize } from '../../store/deviceScreenSize';
import { useSectionOffsetScroll } from '../../useSectionOffsetScroll';

const GradientCircles = ({ withoutVerticalShift }: { withoutVerticalShift?: boolean }) => {
  const appTheme = useAtomValue(theme);
  const deviceSize = useAtomValue<ScreenSize>(clientDeviceSize);
  const sectionOffset = useSectionOffsetScroll('circles');

  const circleSize: number = deviceSize === ScreenSize.DESKTOP_HUGE
    ? 720 : deviceSize === ScreenSize.DESKTOP_BIG
      ? 520 : deviceSize <= ScreenSize.DESKTOP
        && deviceSize >= ScreenSize.TABLET_LANDSCAPE
        ? 360 : deviceSize === ScreenSize.TABLET_PORTRAIT
          ? 280 : 200;

  const circleMargin: number = deviceSize === ScreenSize.TABLET_PORTRAIT
    ? -(circleSize - 150 / 2) : deviceSize === ScreenSize.TABLET_LANDSCAPE
      ? -(circleSize - 250 / 2) : -(circleSize / 2);

  const gradientCircle = (margin: number): ReactElement => (
    <div
      className={styles.circle}
      style={{ marginLeft: `${margin + sectionOffset / 30}px`, width: circleSize }}
    >
      <img
        src={`/media/consultation/gradient-${appTheme}.svg`}
        alt='circle'
        width={circleSize}
        height={86}
      />
    </div>
  );

  const marginBottom = Math.round(withoutVerticalShift ? 0 : -sectionOffset / 6);

  return (
    <div
      className={styles.circles}
      id='circles'
      style={{
        marginBottom,
      }}
    >
      {gradientCircle(deviceSize < ScreenSize.DESKTOP_BIG ? -100 : -400)}
      {gradientCircle(circleMargin)}
      {gradientCircle(circleMargin)}
      <div
        className={styles.circle}
        style={{ marginLeft: `${circleMargin + sectionOffset / 30}px`, width: circleSize }}
      >
        <img
          src={`/media/consultation/border_${appTheme}.webp`}
          alt='line'
          width={circleSize}
          height={86}
        />
      </div>
    </div>
  );
};

export default GradientCircles;
