'use client';

import { CaseListItem, ICaseResponse } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import React from 'react';
import AnimatedTitle, { AnimationType } from '../../components/AnimatedTitle';
import Button, { ButtonVariants } from '../../components/Button';
import Img from '../../components/Img';
import { theme } from '../../store/theme';
import styles from './CaseCard.module.scss';

type CaseCardProps = {
  data: CaseListItem | ICaseResponse,
  searchView?: boolean,
  casesView?: boolean,
  relatedView?: boolean;
};

const CaseCard = ({
  data, searchView = false, casesView, relatedView,
}: CaseCardProps) => {
  const appTheme = useAtomValue(theme);
  const image = data.previewImage || data.image;
  const description = data.previewDescription || data.description;
  const title = data.previewTitle || data.title;
  const isNDA = (data as CaseListItem)?.isClosed;

  return (
    <article className={classnames(styles.case, {
      [styles.case_searchView]: searchView,
      [styles.case_casesView]: casesView,
      [styles.NDAView]: isNDA,
      [styles.case_relatedView]: relatedView,
    })}
    >
      {searchView ? (
        <div
          className={styles.image}
        >
          <Img
            src={image?.url || '/'}
            alt={image?.alternativeText || ''}
            withPreloader
          />
        </div>
      ) : (
        <Link
          href={isNDA ? '/' : `/cases/${data.slug}`}
          className={classnames(styles.image, {
            [styles.NDAView]: isNDA,
          })}
          onClick={(e) => {
            if (isNDA) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        >
          <div className={styles.imgWrapper}>
            <Img
              src={image ? image.url : '/'}
              alt={image?.alternativeText || ''}
              sizes='(max-width: 1024px) 600px, (max-width: 1280px) 440px, 840px'
              withPreloader
              priority
            />
            <div className={styles.NDAOverlay}>
              <h6 className={classnames(styles.NDATitle, 'title6')}>This Case Study is Under NDA</h6>
              <p className={styles.NDADescription}>
                {'Interested in the specifics? \n Book an individual consultation with our experts to discuss your project.'}
              </p>
              <Button
                link='#contact-form'
                variant={ButtonVariants.OUTLINED}
                className={styles.NDAButton}
              >
                Book a call
              </Button>
            </div>
          </div>
        </Link>
      )}
      <div className={styles.content}>
        <div className={styles.caseInfo}>
          <header className={classnames(styles.infoHeader, {
            [styles.isNDA]: isNDA,
          })}
          >
            <h3 className={styles.projectName}>{data.projectName}</h3>
            <Link
              href={isNDA ? '/' : `/cases/${data.slug}`}
              onClick={(e) => {
                if (isNDA) {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
            >
              <h2
                className={styles.title}
              >
                <AnimatedTitle
                  animationType={AnimationType.NONE}
                  title={title}
                />
              </h2>
            </Link>
          </header>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        {!searchView && (
          <>
            <div className={styles.technologies}>
              <p className={styles.technologies__title}>Technologies</p>
              <div className={styles.technologies__content}>
                {data?.technologies?.map(technology => (
                  <div
                    className={styles.technology}
                    title={technology.name}
                    key={technology.id}
                  >
                    <img
                      loading='lazy'
                      src={technology.image?.[appTheme].url}
                      alt={technology.image?.[appTheme].alternativeText || 'technology image'}
                    />
                  </div>
                ))}
              </div>
            </div>
            {!isNDA && (
            <Button
              link={`/cases/${data.slug}`}
              className={styles.viewButton}
              variant={ButtonVariants.TRANSPARENT}
              icon={(
                <svg>
                  <use
                    xlinkHref='/media/arrow-right-bold.svg#arrowRight'
                    href='/media/arrow-right-bold.svg#arrowRight'
                  />
                </svg>
              )}
              iconPosition='right'
              iconSize={{ width: 24, height: 24 }}
            >
              <span className={styles.buttonContent}>View Project</span>
            </Button>
            )}
          </>
        )}
      </div>
    </article>
  );
};

export default CaseCard;
