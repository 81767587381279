import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import styles from './ReviewsList.module.scss';
import { theme } from '../../../../store/theme';

export type PaginationItem = {
  title: string;
  content: string;
};

interface IPaginationButtonProps {
  item: PaginationItem;
  handleChange: () => void;
  isActive: boolean;
}

const PaginationButton = ({ item, handleChange, isActive }: IPaginationButtonProps) => {
  const appTheme = useAtomValue(theme);
  const withoutTitle = !item.title;
  const formattedContent = item.content.split(', ').join('\n');

  return (
    <button
      type='button'
      className={classNames(styles.paginationButton, {
        [styles.active]: isActive,
      })}
      onClick={handleChange}
    >
      <div className={classNames(styles.buttonContentWrapper, {
        [styles.onlyIndustries]: withoutTitle,
      })}
      >
        {!withoutTitle && (
        <p className={styles.buttonTitle}>
          {item.title}
          {' '}
        </p>
        )}
        <p className={styles.buttonContent}>{formattedContent}</p>
        <img
          src={`/media/ellipses/cases-slider-ellipse-${appTheme}.svg`}
          alt='pagination button ellipse'
          className={styles.activeEllipse}
        />
      </div>
    </button>
  );
};

export default PaginationButton;
